import { Avatar, Drawer, Menu, Row } from "antd"
import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import logol from "src/assets/images/logo/logoLeft.png"
import ROUTER from "src/router"
import MenuItem from "../MenuItems"
import AuthService from "src/services/AuthService"
import { clearStorage } from "src/lib/storage"
import { UserOutlined } from "@ant-design/icons"
import RegisterModal from "src/components/Layouts/component/Register/components/RegisterModal"
import LoginModal from "src/components/Layouts/component/Login/LoginModal"
import ForgetModal from "src/components/Layouts/component/Forget/ForgetModal"
import VerifyForgetModal from "src/components/Layouts/component/Forget/components/VerifyForgotModal"
import RePasswordModal from "src/components/Layouts/component/Forget/components/RePasswordModal"

const MobileMenu = ({
  onClickMenu,
  selectedKey,
  onCancel,
  open,
  userInfo,
  isLogin,
}) => {
  const navigate = useNavigate()
  const [userMenu, setUserMenu] = useState([{}])
  const [rePasswordModal, setRePasswordModal] = useState(false)
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [openForgetPassModal, setOpenForgetPassModal] = useState(false)
  const [openVerifyModal, setOpenVerifyModal] = useState(false)
  const [openRegisterModal, setOpenRegisterModal] = useState(false)
  const [email, setEmail] = useState(false)
  const [codeVerify, setCodeVerify] = useState()

  const onClick = async () => {
    if (isLogin) {
      await AuthService.logout()
      clearStorage()
      return navigate(ROUTER?.HOME)
    }
    setOpenLoginModal(true)
  }
  useEffect(() => {
    if (isLogin && userInfo) {
      setUserMenu({
        label: (
          <div className="d-flex align-items-center">
            <Avatar
              src={userInfo?.Avatar}
              size={30}
              icon={<UserOutlined style={{ fontSize: "20px" }} />}
            />
            <div className="fs-16 ml-8">{userInfo?.FullName}</div>
          </div>
        ),
        key: "userMenu",
        onTitleClick: () => {},
        children: [
          {
            key: ROUTER.TAI_KHOAN_CUA_TOI,
            label: "Thông tin tài khoản",
          },
          {
            key: window.location.pathname,
            label: (
              <span onClick={() => setRePasswordModal(true)}>Đổi mật khẩu</span>
            ),
            onTitleClick: ({ key }) => {
              setRePasswordModal(true)
              navigate(`${window.location.pathname}`)
            },
          },
          {
            key: window.location.pathname,
            label: (
              <span style={{ color: "red" }} onClick={onClick}>
                Đăng xuất
              </span>
            ),
            onClick: () => {
              onClick()
              navigate(window.location.pathname)
            },
          },
        ],
      })
    } else {
      setUserMenu()
    }
  }, [isLogin, userInfo])

  return (
    <Drawer
      title={
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ flex: 10 }}
        >
          <div className="mr-8">
            <img width={34} src={logol} alt="" />
          </div>
          <div className="fs-18 fw-600">CÔNG ĐOÀN Y TẾ</div>
        </div>
      }
      placement="left"
      onClose={() => onCancel()}
      open={open}
      className="menu-custom"
    >
      <Menu
        onClick={key => {
          onClickMenu(key)
          onCancel()
        }}
        selectedKeys={selectedKey}
        defaultOpenKeys={["userMenu"]}
        mode="inline"
        items={[...MenuItem().filter(i => !i?.hideOnMenu), userMenu]}
      />
      {!isLogin && (
        <div
          className="d-flex align-items-center pt-12"
          style={{ color: "#555", borderTop: "1px dashed #ddd" }}
        >
          <Row onClick={() => onClick()} className="d-flex align-items-center">
            <span
              className="fs-16px fw-600 pl-24 pr-16"
              style={{ color: "#555", borderRight: "1px solid #555" }}
            >
              Đăng nhập
            </span>
          </Row>
          <Row onClick={() => setOpenRegisterModal(true)} className="">
            <span className="fs-16px fw-600 pl-16" style={{ color: "#555" }}>
              Đăng ký
            </span>
          </Row>
        </div>
      )}
      {!!openLoginModal && (
        <LoginModal
          openLoginModal={openLoginModal}
          handleCancel={() => setOpenLoginModal(false)}
          handleRegister={() => setOpenRegisterModal(true)}
          setOpenForgetPassModal={() => setOpenForgetPassModal(true)}
        />
      )}
      {!!openForgetPassModal && (
        <ForgetModal
          openForgetPassModal={openForgetPassModal}
          handleOk={() => {}}
          handleCancel={() => setOpenForgetPassModal(false)}
          handleLogin={() => setOpenLoginModal(true)}
          setOpenVerifyModal={() => setOpenVerifyModal(true)}
          setEmail={setEmail}
        />
      )}
      {!!openVerifyModal && (
        <VerifyForgetModal
          openVerifyModal={openVerifyModal}
          handleOk={() => {}}
          handleCancel={() => setOpenVerifyModal(false)}
          handleLogin={() => setOpenLoginModal(true)}
          setRePasswordModal={() => setRePasswordModal(true)}
          email={email}
          setCodeVerify={setCodeVerify}
        />
      )}
      {!!rePasswordModal && (
        <RePasswordModal
          rePasswordModal={rePasswordModal}
          handleOk={() => {}}
          handleCancel={() => setRePasswordModal(false)}
          handleLogin={() => setOpenLoginModal(true)}
          email={email}
          codeVerify={codeVerify}
        />
      )}
      {!!openRegisterModal && (
        <RegisterModal
          openRegisterModal={openRegisterModal}
          handleOk={() => {}}
          handleCancel={() => setOpenRegisterModal(false)}
          handleLogin={() => setOpenLoginModal(true)}
        />
      )}
    </Drawer>
  )
}

export default MobileMenu
