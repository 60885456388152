import { Divider } from "antd"
import LayoutCommon from "src/components/Common/Layout"
import { ServiceContainerStyled, ServicesStyled } from "../styled"

import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import Slider from "react-slick"
import dv1 from "src/assets/images/services/sv1.png"
import dv2 from "src/assets/images/services/sv2.png"
import dv3 from "src/assets/images/services/sv3.png"
import dv4 from "src/assets/images/services/sv4.png"
// import dv5 from "src/assets/images/services/sv5.png"
import dv7 from "src/assets/images/services/sv7.png"
import dvh1 from "src/assets/images/services/svh1.png"
import dvh2 from "src/assets/images/services/svh2.png"
import dvh3 from "src/assets/images/services/svh3.png"
import dvh4 from "src/assets/images/services/svh4.png"
// import dvh5 from "src/assets/images/services/svh5.png"
import dvh7 from "src/assets/images/services/svh7.png"
import { useWindowReSize } from "src/lib/useWindowResize"

import SvgIcon from "src/components/SvgIcon"
import ROUTER from "src/router"
import { GUIDE_EMPTY } from "src/constants/constants"
import useWindowSize from "src/lib/useWindowSize"
export const ServicePostID = [
  {
    titleCode: "CONG_KHAI_TAI_CHINH",
    CategoryPostID: "2d4262da-a322-4eed-8b57-07c36194ca2d",
  },
  {
    titleCode: "PHUC_LOI_DOAN_VIEN",
    CategoryPostID: "00000000-0000-0000-0000-000000001302",
  },
  {
    titleCode: "THONG BAO MOI THAU",
    CategoryPostID: "85011a28-cb52-4a4f-b5da-a070b11cd0f6",
  },
  {
    titleCode: "LICH_CONG_TAC",
    CategoryPostID: "f3bef07d-fc53-4549-90cb-24df114fde3d",
  },
]

export const ServiceID = [
  "2d4262da-a322-4eed-8b57-07c36194ca2d",
  "00000000-0000-0000-0000-000000001302",
  "85011a28-cb52-4a4f-b5da-a070b11cd0f6",
]
export const LIST_SERVICES = [
  {
    title: "Văn bản / Tra cứu",
    icon: dv1,
    content: "",
    router: `${ROUTER.TIM_KIEM_VAN_BAN}/${GUIDE_EMPTY}`,
    iconHover: dvh1,
  },
  {
    title: "Sổ tay công đoàn",
    content: "",
    icon: dv7,
    router: `${ROUTER.TIM_KIEM_TAI_LIEU}/${GUIDE_EMPTY}`,
    iconHover: dvh7,
  },
  {
    title: "Phúc lợi đoàn viên",
    icon: dv3,
    iconHover: dvh3,
    content: "",
    router: `${ROUTER.DANH_SACH_TIN_TUC}/${ServiceID[1]}`,
    // onClick: () => window.open("https://ytedoanhnghiep.xyz/", "_blank"),
  },
  {
    title: "Thông báo mời thầu",
    content: "",
    icon: dv4,
    router: `${ROUTER.DANH_SACH_TIN_TUC}/${ServiceID[2]}`,
    iconHover: dvh4,
  },
  {
    title: "Công khai tài chính",
    icon: dv2,
    content: "",
    router: `${ROUTER.DANH_SACH_TIN_TUC}/${ServiceID[0]}`,
    iconHover: dvh2,
  },
]

const Services = () => {
  const dimensions = useWindowReSize()
  const navigate = useNavigate()

  const SlideRef = useRef()
  const settings = {
    autoplay: true,
    slidesToScroll: 1,
    slidesToShow: dimensions.width < 1000 ? 2 : dimensions.width < 1200 ? 4 : 6,
    //Cho kéo hoặc không
    // draggable: false,
    accessibility: false,
    infinite: false,
    ref: SlideRef,
    nextArrow: (
      <SvgIcon
        onClick={() => SlideRef?.current?.slickNext()}
        name="next-arrow-slide"
      />
    ),
    prevArrow: (
      <SvgIcon
        onClick={() => SlideRef?.current?.slickPrev()}
        name="prev-arrow-slide"
      />
    ),
  }
  return (
    <ServiceContainerStyled
    // data-aos="fade-up"
    >
      <LayoutCommon>
        <Slider {...settings}>
          {LIST_SERVICES?.map((i, idx) => (
            <ServicesStyled
              key={`services${idx}`}
              item={i}
              onClick={() => {
                window.scroll(0, 0)
                !!i?.onClick && i?.onClick()
                !!i?.router && navigate(i?.router)
              }}
              className="item-shadow"
            >
              <div className="icon-service" />
              <div
                className="title-service"
                dangerouslySetInnerHTML={{ __html: i?.title }}
              />
            </ServicesStyled>
          ))}
        </Slider>
      </LayoutCommon>
    </ServiceContainerStyled>
  )
}

export default Services
