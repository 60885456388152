import { DownOutlined, MenuFoldOutlined, UserOutlined } from "@ant-design/icons"
import { Avatar, Col, Divider, Dropdown, Layout, Menu, Row } from "antd"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import logo from "src/assets/images/logo/logoLeft.png"
import LayoutCommon from "src/components/Common/Layout"
import ForgetModal from "src/components/Layouts/component/Forget/ForgetModal"
import RePasswordModal from "src/components/Layouts/component/Forget/components/RePasswordModal"
import VerifyForgetModal from "src/components/Layouts/component/Forget/components/VerifyForgotModal"
import LoginModal from "src/components/Layouts/component/Login/LoginModal"
import RegisterModal from "src/components/Layouts/component/Register/components/RegisterModal"
import SvgIcon from "src/components/SvgIcon"
import { SubTableHeader } from "src/components/Table/CustomTable/styled"
import { ACCOUNT_TYPE } from "src/constants/constants"
import STORAGE, { clearStorage, getStorage, setStorage } from "src/lib/storage"
import {
  default as UseWindowSize,
  default as useWindowSize,
} from "src/lib/useWindowSize"
import { hasPermission } from "src/lib/utils"
import ROUTER from "src/router"
import AuthService from "src/services/AuthService"
import Footer from "../Footer"
import BreadcrumbHomeLanding from "./BreadcrumbHomeLanding"
import MenuItem from "./MenuItems"
import MobileMenu from "./component/MobileMenu"
import { CustomMenuStyled, LayoutStyled, StyleMenuAccount } from "./styled"
import "./styles.scss"
import BannerLanding from "./component/BannerLanding"
import LoginFBLD from "../VoteAuth/components/ListAuthor/LoginFBLD"
const { Header, Content } = Layout
const ColorCode = {
  backgroundLinearMenu: "linear-gradient(178deg, #373b44, #345d9e, #fff0)",
  backgroundMenu: "var(--color-landing-page-header)",
  textMenu: "#0d99ff",
}
const LandingPageLayout = ({ children, isAdmin }) => {
  const { listTabs } = useSelector(state => state?.appGlobal)
  const { colors } = useSelector(state => state?.banner)
  const isLogin = getStorage(STORAGE.TOKEN)
  const isMobile = useWindowSize.isMobile() || false
  const navigate = useNavigate()
  const location = useLocation()
  const [userInfo, setUserInfo] = useState(getStorage(STORAGE.USER_INFO))
  const [email, setEmail] = useState(false)
  const [codeVerify, setCodeVerify] = useState()
  const [open, setOpen] = useState(false)
  const [selectedKey, setSelectedKey] = useState(
    getStorage(STORAGE.KEY_MENU_ACTIVE) || ["/"],
  )
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [openForgetPassModal, setOpenForgetPassModal] = useState(false)
  const [openVerifyModal, setOpenVerifyModal] = useState(false)
  const [rePasswordModal, setRePasswordModal] = useState(false)
  const [openRegisterModal, setOpenRegisterModal] = useState(false)
  const [collapseMenuAdmin, setCollapseMenuAdmin] = useState(false)

  useEffect(() => {
    let key = location?.pathname
    if (location?.pathname === ROUTER?.DANG_BAI)
      key = ROUTER?.DANH_SACH_BAI_VIET
    setSelectedKey([key])
  }, [location])

  const onClickMenu = key => {
    setStorage(STORAGE.KEY_MENU_ACTIVE, key.keyPath)
    setSelectedKey(key.key.keyPath)
    if (!key.key.includes("subkey")) {
      navigate(key.key)
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    }
  }
  const onClick = async () => {
    if (isLogin) {
      await AuthService.logout()
      clearStorage()
      return navigate(ROUTER?.HOME)
    }
    setOpenLoginModal(true)
  }

  const fbTest = () => {
    let chatbox = document.getElementById("fb-customer-chat")
    if (chatbox) {
      chatbox?.setAttribute("page_id", `109362535310723`)
      chatbox?.setAttribute("attribution", "biz_inbox")
      window.fbAsyncInit = function () {
        /*global FB*/
        FB?.init({
          xfbml: true,
          version: "v14.0",
        })
      }
      ;(function (d, s, id) {
        let js,
          fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) return
        js = d.createElement(s)
        js.id = id
        js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"
        fjs.parentNode.insertBefore(js, fjs)
      })(document, "script", "facebook-jssdk")
    }
  }
  useEffect(() => {
    fbTest()
  }, [])

  const menu = (
    <StyleMenuAccount>
      <div className="menu-account">
        <Menu className="dropdown-option">
          <div className="account-infor">
            <Row gutter={[16, 8]} className="infor">
              <Col span={6}>
                <div className="text-center">
                  <Avatar
                    src={userInfo?.Avatar}
                    size={52}
                    icon={<UserOutlined style={{ fontSize: "52px" }} />}
                  />
                </div>
              </Col>
              <Col span={18}>
                <div className="sumary-infor-user">
                  {!!userInfo?.FullName && (
                    <div className="fullname">{userInfo?.FullName}</div>
                  )}
                  {!!userInfo?.Username && (
                    <SubTableHeader className="max-line1">
                      {userInfo?.Username}
                    </SubTableHeader>
                  )}
                </div>
              </Col>
            </Row>
            <Divider />
            {/* {!!(
              userInfo?.AccountType === ACCOUNT_TYPE.ADMIN ||
              userInfo?.AccountType === ACCOUNT_TYPE.USER
            ) && (
              <Menu.Item
                key="1"
                onClick={() => {
                  let startPage = undefined
                  if (!!menuAdmin && !!menuAdmin[0]?.children?.length) {
                    startPage = menuAdmin[0]?.children[0]?.key
                  } else if (!!(menuAdmin[0]?.key?.charAt(0) === "/")) {
                    startPage = menuAdmin[0]?.key
                  }
                  navigate(!!menuAdmin?.length ? startPage : ROUTER.HOME)
                }}
              >
                <div className="btn-function">
                  <SvgIcon name="user_login" />
                  <span className="fw-400">Quản trị hệ thống</span>
                </div>
              </Menu.Item>
            )} */}
            <Menu.Item
              key="2"
              onClick={() => {
                navigate(ROUTER.TAI_KHOAN_CUA_TOI)
              }}
            >
              <div className="btn-function">
                <SvgIcon name="file-text" />
                <span className="fw-400">Thông tin của tôi</span>
              </div>
            </Menu.Item>
            {/* <Menu.Item
              key="3"
              onClick={() => {
                setRePasswordModal(true)
              }}
            >
              <div className="btn-function strok-btn-function">
                <SvgIcon name="reset-pass" />
                <span className="fw-400">Đổi mật khẩu</span>
              </div>
            </Menu.Item> */}
          </div>
          <div className="account-logout">
            <Menu.Item key="4" style={{ color: "#ED1117" }} onClick={onClick}>
              <div className="btn-logout">
                <SvgIcon name="logoutIcon" />
                Đăng xuất
              </div>
            </Menu.Item>
          </div>
        </Menu>
      </div>
    </StyleMenuAccount>
  )

  const menuAdmin = MenuItem()
    ?.filter(i => i?.showOnAdmin)
    ?.filter(x => hasPermission(x?.TabID, [...listTabs]))
    ?.map(i => ({
      ...i,
      children: i?.children?.filter(x =>
        hasPermission(x?.TabID, [...listTabs]),
      ),
    }))
  const { banner } = useSelector(state => state.banner)

  const isSmallMobile = !!useWindowSize.isSmallMobile()

  return (
    <LayoutStyled
      TextHeaderColor={ColorCode.textMenu}
      isMobile={UseWindowSize.isMobile()}
    >
      <Header
        className={`header-background ${""}`}
        style={{
          background: ColorCode.backgroundMenu,
          position: "sticky",
          top: 0,
          zIndex: 109,
          boxShadow: "1px 1px 2px #ddd",
        }}
      >
        <div
          style={{
            background: ColorCode.backgroundMenu,
            border: "none",
          }}
        >
          <LayoutCommon>
            <Row
              className="align-items-sb "
              style={{
                padding: UseWindowSize.isMobile()
                  ? `10px 5px`
                  : `0px 5px 10px 5px`,
                width: "100%",
              }}
            >
              <div
                className="d-flex-end"
                style={{
                  width: "100%",
                  height: UseWindowSize.isMobile() ? "30px" : "unset",
                }}
              >
                {React.createElement(
                  UseWindowSize.isMobile() ? MenuFoldOutlined : Col,
                  {
                    onClick: () => setOpen(true),
                    className:
                      UseWindowSize.isMobile() && " white-icon mobile-icon",
                    style: { height: "50px" },
                  },
                )}
                {!!UseWindowSize.isMobile() && (
                  <div
                    style={{ color: ColorCode.textMenu }}
                    className={`name-branch ml-8 ${"hover-menu"}`}
                  >
                    <span className={`fw-600  fs-12`}>
                      Công đoàn y tế Việt Nam
                    </span>
                  </div>
                )}
                <div className="align-items-center d-flex" style={{ flex: 1 }}>
                  <CustomMenuStyled
                    className={`${"menu-user "}`}
                    style={isMobile ? { display: "none" } : {}}
                    TextHeaderColor={ColorCode.textMenu}
                  >
                    <Menu
                      className="d-flex-end"
                      onClick={key => onClickMenu(key)}
                      selectedKeys={selectedKey}
                      mode="horizontal"
                      items={MenuItem().filter(i => !i?.hideOnMenu)}
                      // className={`${isAdmin ? "blue-header" : ""}`}
                      style={{
                        backgroundColor: "transparent",
                        color: ColorCode.textMenu,
                      }}
                    />
                  </CustomMenuStyled>
                </div>
                <div className="align-items-center d-flex">
                  {/* {!isMobile && ( */}
                  <>
                    {!!isLogin ? (
                      <div className="d-flex justify-content-flex-end align-items-end">
                        <Dropdown
                          overlay={menu}
                          overlayStyle={{ minWidth: "300px" }}
                        >
                          <div
                            className={`d-flex align-items-center pointer ${
                              !UseWindowSize.isMobile() && "pt-10"
                            } pl-12`}
                          >
                            <span
                              style={{ color: colors?.textMenu }}
                              className="pr-8"
                            >
                              {/* {!!userInfo?.FullName && (
                                  <div
                                    className="fullname mr-6 ml-6 pt-10"
                                    style={{ whiteSpace: "nowrap" }}
                                  >
                                    {userInfo?.FullName}
                                  </div>
                                )} */}

                              <div className="text-center">
                                <Avatar
                                  src={userInfo?.Avatar}
                                  size={32}
                                  icon={
                                    <UserOutlined
                                      style={{ fontSize: "52px" }}
                                    />
                                  }
                                />
                              </div>
                            </span>
                            <DownOutlined
                              style={{ fontSize: "12px", fontWeight: 600 }}
                            />
                            {/* <SvgIcon
                                name="arrowDown"
                                // className={"white-icon"}
                              /> */}
                          </div>
                        </Dropdown>
                      </div>
                    ) : (
                      <div className="d-flex align-items-center ">
                        <Row
                          onClick={onClick}
                          className="align-items-center pointer pt-10 fs-14 fw-600 pl-12 pr-12"
                          style={{
                            flexWrap: "nowrap",
                            border: "none",
                            color: ColorCode.textMenu,
                          }}
                        >
                          {/* <SvgIcon name="user_login" className="login-icon" /> */}
                          <span className="login-item_text">ĐĂNG NHẬP</span>
                        </Row>
                      </div>
                    )}
                  </>
                  {/* )} */}
                </div>
              </div>
            </Row>
            {!isAdmin && <Divider style={{ margin: 0 }} />}
          </LayoutCommon>
        </div>
      </Header>
      {!!isAdmin && <div className="spacement_pp" />}

      <BreadcrumbHomeLanding />
      <Layout className="">
        <Content className="site-layout-background">
          <div
            className="w-100 "
            style={{ backgroundColor: colors?.BackGroundColor }}
          >
            {children}
          </div>
          {!isAdmin && <Footer />}
        </Content>
      </Layout>
      <MobileMenu
        open={open}
        onCancel={() => {
          setOpen(false)
        }}
        onClickMenu={onClickMenu}
        selectedKey={selectedKey}
        userInfo={userInfo}
        isLogin={isLogin}
      />
      <LoginFBLD
        openLoginModal={openLoginModal}
        setOpenLoginModal={setOpenLoginModal}
      />
    </LayoutStyled>
  )
}

export default LandingPageLayout
