import { Breadcrumb } from "antd"
import BreadcrumbItem from "antd/es/breadcrumb/BreadcrumbItem"
import React from "react"
import { useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router"
import LayoutCommon from "src/components/Common/Layout"
import LayoutAdminCommon from "src/components/Common/LayoutAdmin"
import { findParent, treeToList } from "src/lib/utils"
import ROUTER from "src/router"
import { MenuItemBreadcrumb } from "../MenuItems"

const BreadcrumbHomeLanding = ({}) => {
  const location = useLocation()
  console.log("location", location)
  const listParent = MenuItemBreadcrumb()?.filter(item =>
    location?.pathname?.includes(item?.key),
  )

  const hideBread = location?.pathname === ROUTER.BINH_CHON
  // listParent?.[0]?.key?.includes("submenu") ||
  // [ROUTER.BINH_CHON]?.includes(listParent?.[0]?.key)
  return (
    <>
      {!!listParent?.length && !hideBread && (
        <div className="box-breadcrumb-header">
          <div className="breadcrumb-header">
            <LayoutCommon>
              <Breadcrumb separator=">">
                <BreadcrumbItem
                  style={{
                    cursor: "pointer",
                  }}
                  href={ROUTER?.BINH_CHON}
                >
                  Trang chủ
                </BreadcrumbItem>
                {listParent?.map((i, idx) => (
                  <BreadcrumbItem
                    style={{
                      cursor:
                        !i?.key?.includes("subkey") &&
                        idx !== listParent?.length - 1
                          ? "pointer"
                          : "unset",
                    }}
                    href={
                      i?.key?.includes("subkey") ||
                      idx === listParent?.length - 1
                        ? undefined
                        : i?.key
                    }
                    key={i?.key}
                  >
                    {i?.label}
                  </BreadcrumbItem>
                ))}
              </Breadcrumb>
            </LayoutCommon>
          </div>
        </div>
      )}
    </>
  )
}

export default BreadcrumbHomeLanding
