import { Col, Divider, Row } from "antd"
import { useNavigate } from "react-router-dom"
import { useWindowReSize } from "src/lib/useWindowResize"
import ROUTER from "src/router"
import styled from "styled-components"
import { useSelector } from "react-redux"
import useWindowSize from "src/lib/useWindowSize"
import SvgIcon from "src/components/SvgIcon"
import LayoutCommon from "src/components/Common/Layout"

const FooterStyled = styled.div`
  position: relative;
  background: #d9d9d970;
  border-top: 1px solid #cccccc91;
  .div-map2 {
    iframe {
      max-width: 95%;
    }
  }
  .div-map {
    iframe {
      max-width: 100%;
    }
  }
  .div-map {
    width: 100%;
    /* min-height: 150px; */
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0;
    padding-top: 0px;
    iframe {
      /* height: calc(100% - 8px) !important;
      height: 90% !important; */

      max-width: 100%;
    }
  }
  p {
    margin-bottom: 0;
  }
  .to-top {
    .title-to-top {
      display: none;
    }
    position: fixed;
    bottom: ${props => (props.isMobile ? "32px" : "62px")};
    right: ${props => (props.isMobile ? "32px" : "62px")};
    :hover {
      .title-to-top {
        display: flex;
      }
    }
  }
  .ant-menu-title-content {
    color: #838383 !important;
    font-weight: 400 !important;
  }
  .ant-menu-item {
    padding: 0px;
  }
  .ant-menu-vertical {
    border-right: unset !important;
  }
  .dkkd {
    font-weight: 600;
    font-size: 12px;
    color: #838383;
  }
  .button-to-top {
    background: #ffffff;
    box-shadow: -2px 1px 4px rgba(0, 0, 0, 0.1);
    width: 40px;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .orange-color {
    font-weight: 600;
    font-size: 12px;
    color: #f88c00;
  }

  .ant-menu-vertical .ant-menu-item {
    border: unset !important;
    padding: 0px !important;
  }

  .ant-menu-item,
  .ant-menu-submenu {
    top: 0 !important;
  }
  .ant-menu-item:hover .ant-menu-title-content {
    color: #838383 !important;
  }
  .ant-menu-item-selected {
    background-color: #ffffff !important;
    .ant-menu-item:hover .ant-menu-title-content,
    .ant-menu-title-content {
      color: #838383 !important;
    }
  }
  .input-email {
    width: 359px;
    background: #f4f6fb;
    border-radius: 4px;
    border: unset;
    input {
      background: transparent;
      border: unset;
    }
    .ant-btn,
    .ant-input-group-addon {
      border: unset;
      background: transparent;
    }
    .ant-btn-primary {
      text-shadow: unset;
      box-shadow: unset;
    }
    .ant-input:focus {
      border: unset;
      box-shadow: unset;
    }
  }
`

const items = [
  {
    label: "Giới thiệu",
    key: ROUTER.HOME,
  },
  {
    label: "Kết quả nghiên cứu",
    key: ROUTER.INTRODUCE,
  },
  {
    label: "Sức khỏe nghề nghiệp",
    key: ROUTER.NEWS,
  },
  {
    label: "Môi trường và sức khỏe",
    key: ROUTER.SERVICES,
  },
  {
    label: "Chỉ đạo tuyến",
    key: ROUTER.CDT_CDVTD,
  },
]

const Footer = () => {
  const navigate = useNavigate()
  const onChange = menu => navigate(menu?.key)

  const isMobile = !!useWindowSize.isMobile()
  const { footer } = useSelector(state => state.banner)
  const dimensions = useWindowReSize()
  return (
    <FooterStyled isMobile={isMobile}>
      <LayoutCommon>
        <Row className="pl-12 pr-17 pt-24">
          <Col xs={24} md={12}>
            <div
              dangerouslySetInnerHTML={{
                __html: footer?.Footer,
              }}
            />
          </Col>
          <Col xs={24} md={12} className="p-0">
            <div
              className={isMobile ? "div-map2" : "div-map"}
              dangerouslySetInnerHTML={{
                __html: footer?.Map,
              }}
            />
          </Col>
        </Row>
      </LayoutCommon>
      <Divider style={{ marginTop: 12 }} />
      <LayoutCommon>
        <Row gutter={10} className="justify-content-center mb-16">
          <span className="dkkd ">
            <span className="black-color">© 2020</span> Bản quyền thuộc{" "}
            <span className="black-color">CÔNG ĐOÀN Y TẾ VIỆT NAM</span>
          </span>
          <br />
        </Row>
      </LayoutCommon>
      <Row
        className="align-items-center justify-content-flex-end to-top pointer"
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
      >
        {/* <Col className="m-10 orange-color title-to-top">LÊN ĐẦU TRANG</Col> */}
        <Col className="button-to-top">
          <SvgIcon name="arrow-up-orange" />
        </Col>
      </Row>
      {/* )} */}
    </FooterStyled>
  )
}

export default Footer
