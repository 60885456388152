import SvgIcon from "src/components/SvgIcon"
import ROUTER from "src/router"

export const MenuItemBreadcrumb = () => {
  return [
    {
      label: "Chi tiết tin tức",
      key: ROUTER.NEWS,
    },
    {
      key: ROUTER.BINH_CHON,
      icon: <SvgIcon name="home" />,
    },
    {
      label: "Thể lệ",
      key: ROUTER.THE_LE,
    },
    {
      label: "Danh sách bình chọn",
      key: ROUTER.DANH_SACH_TAC_GIA,
    },
    {
      label: "Kết quả bình chọn",
      key: ROUTER.BANG_XEP_HANG,
    },
    {
      label: "Tin tức",
      key: ROUTER.TIN_TUC_BINH_CHON,
    },
    {
      label: "Chi tiết tin tức",
      key: ROUTER.CHI_TIET_TIN_TUC_BINH_CHON,
    },
    {
      label: "Chi tiết tác giả",
      key: ROUTER.CHI_TIET_TAC_GIA,
    },
  ]
}
const MenuItem = () => {
  return [
    {
      key: ROUTER.BINH_CHON,
      label: "TRANG CHỦ",
    },
    {
      label: "Thể lệ",
      key: ROUTER.THE_LE,
    },
    {
      label: "Danh sách bình chọn",
      key: ROUTER.DANH_SACH_TAC_GIA,
    },
    {
      label: "Kết quả bình chọn",
      key: ROUTER.BANG_XEP_HANG,
    },
    {
      label: "Tin tức",
      key: ROUTER.TIN_TUC_BINH_CHON + "/tin-tuc-binh-chon",
    },
  ]
}
export default MenuItem
