import { useEffect, useState } from "react"
import { sort } from "src/pages/ADMIN/PostManager/components/TreeCategory"
import ROUTER from "src/router"
import GuestServices from "src/services/GuestServices"
import { useNavigate } from "react-router-dom"
import SvgIcon from "../SvgIcon"
import { useDispatch } from "react-redux"
import { setListPost, setMenuPost } from "src/redux/menupost"
import { ServiceID } from "src/pages/ANONYMOUS/Home/component/Services"
import { GUIDE_EMPTY } from "src/constants/constants"

export const MenuItemBreadcrumb = () => {
  return [
    {
      key: ROUTER.HOME,
      icon: <SvgIcon name="home" />,
    },
    {
      label: "Chi tiết tin tức",
      key: ROUTER.NEWS,
    },
    {
      label: "Tìm kiếm tin tức",
      key: ROUTER?.TIM_KIEM,
      hideonmenu: true,
    },
  ]
}
const MenuItem = () => {
  const navigate = useNavigate()
  const [lstCate, setLstCate] = useState([])
  const [listMenuIntroduce, setListMenuIntroduce] = useState([])
  const [listMenuEvent, setListMenuEvent] = useState([])
  const [listMenuFindText, setListMenuFindText] = useState([])
  const [listMenuFindDocument, setListMenuFindDocument] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    getListCategory()
    // getListService()
  }, [])
  const filterListMenu = (list, ParentID) => {
    return list
      ?.filter(
        item =>
          item?.ParentID === ParentID &&
          !ServiceID?.includes(item?.CategoryPostID),
      )
      ?.map(i => ({
        label: i?.CategoryPostName,
        CategoryPostID: i?.CategoryPostID,
        key: `${ROUTER.DANH_SACH_TIN_TUC}/${i?.CategoryPostID}`,
        onTitleClick: ({ key }) => navigate(key?.replace("submenu", "")),
        children: list?.filter(item => item?.ParentID === i?.CategoryPostID)
          ?.length
          ? filterListMenu(list, i?.CategoryPostID)
          : undefined,
      }))
  }

  const fillRedux = (list, ParentID) => {
    return list
      ?.filter(item => item?.ParentID === ParentID)
      ?.map(i => ({
        label: i?.CategoryPostName,
        key: `${i?.CategoryPostID}`,
        // onTitleClick: ({ key }) => navigate(key?.replace("submenu", "")),
        children: list?.filter(item => item?.ParentID === i?.CategoryPostID)
          ?.length
          ? fillRedux(list, i?.CategoryPostID)
          : undefined,
      }))
  }
  const fillPost = (list, ParentID) => {
    let children = []
    let categoryPostIDs = []
    let grandchildren = []

    children = list?.filter(item => item?.ParentID === ParentID)
    if (children?.length) {
      categoryPostIDs = children?.map(item => item?.CategoryPostID)
      grandchildren = children?.map(item => fillPost(list, item.CategoryPostID))
      if (categoryPostIDs?.length && grandchildren?.length) {
        return [...categoryPostIDs, ...grandchildren]
      } else if (categoryPostIDs?.length) {
        return [...categoryPostIDs]
      } else if (grandchildren?.length) {
        return [...grandchildren]
      } else return []
    } else return []
  }
  const getListCategory = async () => {
    //Tin tức
    try {
      const res = await GuestServices.getStaticNav({ Type: 1 })
      const resAgency = await GuestServices.getListAgencyIssued()
      const resField = await GuestServices.getListField({ Type: 4 })
      if (res?.isError) return
      setListMenuFindText(
        resAgency?.Object?.data
          ?.filter((item, idx) => item?.Status === 1)
          ?.map(i => ({
            label: i?.CategoryPostName,
            key: `${ROUTER.TIM_KIEM_VAN_BAN}/${i?.CategoryPostID}`,
          })),
      )
      setListMenuFindDocument(
        resField?.Object?.data
          ?.filter(
            (item, idx) =>
              item?.Status === 1 && idx !== resField?.Object?.data?.length - 1,
          )
          ?.map(i => ({
            label: i?.TopicCategoryName,
            key: `${ROUTER.TIM_KIEM_TAI_LIEU}/${i?.TopicCategoryID}`,
          })),
      )
      let listSort = res?.Object ? sort(res?.Object) : []
      let listMenu = filterListMenu(listSort, GUIDE_EMPTY)
      setLstCate(
        listMenu.filter(
          i =>
            ![
              "gioi-thieu",
              "cong-khai-tai-chinh",
              "thong-bao-moi-thau",
              "su-kien-noi-bat",
            ]?.includes(i?.CategoryPostID),
        ),
      )
      setListMenuIntroduce(
        listMenu
          .filter(i => i.key.includes("gioi-thieu"))?.[0]
          ?.children?.map(i => ({
            ...i,
            onTitleClick: ({ key }) =>
              navigate(key?.replace("submenu", ""), {
                state: { passPage: true },
              }),
          })) || [],
      )

      setListMenuEvent(
        listMenu
          .filter(i => i.key.includes("su-kien-noi-bat"))?.[0]
          ?.children?.map(i => ({
            ...i,
            onTitleClick: ({ key }) =>
              navigate(key?.replace("submenu", ""), {
                state: { passPage: true },
              }),
          })) || [],
      )

      dispatch(setMenuPost(fillRedux(listSort, GUIDE_EMPTY)))
      let fillPosts = fillPost(listSort, "00000000-0000-0000-0000-000000000030")
      if (fillPosts?.length) {
        dispatch(
          setListPost([
            "tin-tuc",
            "--ban-thuong-vu",
            "--ban-chap-hanh",
            fillPosts?.flat(Infinity),
          ]),
        )
      } else {
        dispatch(setListPost(["tin-tuc", "--ban-thuong-vu", "--ban-chap-hanh"]))
      }
    } finally {
    }
  }

  return [
    {
      key: ROUTER.HOME,
      icon: <SvgIcon name="home" />,
    },
    {
      label: "GIỚI THIỆU",
      key: `submenu${ROUTER.GIOI_THIEU}/2`,
      onTitleClick: ({ key }) => navigate(key?.replace("submenu", "")),
      children: [
        {
          key: `${ROUTER.GIOI_THIEU}/2`,
          label: "Lịch sử công đoàn",
        },
        {
          key: `${ROUTER.GIOI_THIEU}/3`,
          label: "Các kỳ đại hội",
        },
        ...listMenuIntroduce?.filter(item => item),
        {
          key: `${ROUTER.GIOI_THIEU}/4`,
          label: "Cơ quan CĐYTVN",
        },
      ],
    },
    ...lstCate?.filter(item => item),
    {
      label: "Tài khoản của tôi",
      key: ROUTER.TAI_KHOAN_CUA_TOI,
      hideOnMenu: true,
    },
    {
      label: "Văn bản",
      key: ROUTER.TIM_KIEM_VAN_BAN,
      onTitleClick: ({ key }) => navigate(key),
      children: listMenuFindText || undefined,
    },
    {
      label: "Sổ tay công đoàn",
      key: ROUTER.TIM_KIEM_TAI_LIEU,
      onTitleClick: ({ key }) => navigate(key),
      children: listMenuFindDocument || undefined,
    },
    {
      label: "Sự kiện",
      key: ROUTER.THU_VIEN,
      onTitleClick: ({ key }) => navigate(key?.replace("submenu", "")),
      children: [
        {
          key: "/thu-vien-hinh-anh",
          label: "Thư viện hình ảnh",
        },
        {
          key: ROUTER.DANH_SACH_VIDEO,
          label: "Video",
        },
        {
          key: ROUTER.TAI_LIEU_HDNV,
          label: "Sách, tài liệu hướng dẫn nghiệp vụ",
        },
        {
          key: "/danh-sach-tin-tuc/su-kien-noi-bat",
          label: "Sự kiện nổi bật",
          children: listMenuEvent?.length ? listMenuEvent : undefined,
        },
      ],
    },
    {
      label: "Liên hệ",
      key: ROUTER.LIEN_HE,
      onTitleClick: ({ key }) => navigate(key?.replace("submenu", "")),
      children: [
        {
          key: ROUTER.LIEN_HE,
          label: "Yêu cầu hỗ trợ",
        },
        {
          key: "/danh-sach-tin-tuc/so-tay-lien-hệ",
          label: "Sổ tay điện thoại",
        },
      ],
    },
    {
      key: ROUTER.TONG_QUAN,
      label: "Tổng quan",
      icon: <SvgIcon name="dashboard" />,
      hideOnMenu: true,
      showOnAdmin: true,
      TabID: [1],
    },
    {
      label: "Quản lý bài đăng",
      key: "subkey1",
      hideOnMenu: true,
      showOnAdmin: true,
      icon: <SvgIcon name="post-card" />,
      TabID: [2, 3, 4],
      children: [
        {
          key: ROUTER.DANH_SACH_BAI_VIET,
          label: "Danh mục - Bài viết",
          TabID: [2, 3],
        },
        {
          key: ROUTER.DANH_MUC_THE,
          label: "Danh sách thẻ",
          TabID: [4],
          hideOnMenu: true,
          showOnAdmin: true,
        },
      ],
    },

    {
      key: ROUTER.YEU_CAU_HO_TRO,
      label: "Yêu cầu hỗ trợ",
      hideOnMenu: true,
      showOnAdmin: true,
      icon: <SvgIcon name="service-support" />,
      TabID: [23],
      // children: [
      //   {
      //     key: ROUTER.YEU_CAU_HO_TRO,
      //     label: "Yêu cầu hỗ trợ",
      //     TabID: [1],
      //   },
      // ],
    },
    {
      key: "subkey3",
      label: "Văn bản",
      icon: <SvgIcon name="file-text" />,
      hideOnMenu: true,
      showOnAdmin: true,
      TabID: [6, 7, 9],
      children: [
        {
          key: ROUTER.VAN_BAN,
          label: "Danh sách",
          TabID: [9],
        },
        {
          key: ROUTER.LOAI_VAN_BAN,
          label: "Loại văn bản",
          TabID: [6],
        },
        {
          key: ROUTER.CO_QUAN_BAN_HANH,
          label: "Cơ quan ban hành",
          TabID: [7],
        },
      ],
    },
    {
      key: ROUTER.TAI_LIEU,
      label: "Sổ tay công đoàn",
      icon: <SvgIcon name="manager-file" />,
      hideOnMenu: true,
      showOnAdmin: true,
      TabID: [10],
    },
    {
      key: ROUTER.HINH_ANH,
      label: "Hình ảnh",
      icon: <SvgIcon name="manager-image" />,
      hideOnMenu: true,
      showOnAdmin: true,
      TabID: [11],
    },
    {
      key: ROUTER.VIDEO,
      label: "Video",
      icon: <SvgIcon name="manager-video" />,
      hideOnMenu: true,
      showOnAdmin: true,
      TabID: [12],
    },

    {
      key: ROUTER.BANNER_FOOTER,
      label: "Cài đặt cấu hình",
      icon: <SvgIcon name="img-file" />,
      hideOnMenu: true,
      showOnAdmin: true,
      TabID: [20],
    },
    {
      label: "Quản trị hệ thống",
      key: "subkey4",
      hideOnMenu: true,
      showOnAdmin: true,
      icon: <SvgIcon name="management-skdn" />,
      TabID: [13, 14, 15, 16, 17, 18, 19, 24],
      children: [
        {
          key: ROUTER.DANH_BA,
          label: "Danh bạ",
          TabID: [15, 16, 17, 18, 19],
        },
        {
          key: ROUTER.HE_THONG_TO_CHUC,
          label: "Hệ thống tổ chức",
          TabID: [24],
        },
        {
          key: ROUTER.PHAN_QUYEN,
          label: "Phân quyền",
          TabID: [13],
        },
        {
          key: ROUTER.LS_HOAT_DONG,
          label: "Lịch sử hoạt động",
          TabID: [14],
        },
      ],
    },
    {
      label: "Nội dung chi tiết",
      key: ROUTER?.NEWS,
      hideOnMenu: true,
    },
    {
      label: "Tìm kiếm",
      key: ROUTER?.TIM_KIEM,
      hideOnMenu: true,
    },
  ]
}
export default MenuItem
