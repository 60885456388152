import http from "./index"
import {
  apiBusinessRegister,
  apiChangePassword,
  apiForgotPassword,
  apiLogin,
  apiLogout,
  apiRegister,
  apiVerifyCode,
  apiLoginGoole,
  apiCallBackLoginGoole,
  apiLoginFB,
  apiLoginFB2,
  apiLoginZalo,
  apiVerifyCodeRegister,
  apiResendOtp,
} from "./apiRouter"
import QueryString from "qs"

const login = body => http.post(apiLogin, body)
const register = body => http.post(apiRegister, body)
const forgotPass = body => http.post(apiForgotPassword, body)
const verifyCode = body => http.post(apiVerifyCode, body)
const verifyCodeRegister = body => http.post(apiVerifyCodeRegister, body)
const resendOtp = body => {
  const params = QueryString.stringify(body)
  return http.post(`${apiResendOtp}?${params}`)
}
const changePassword = body => http.post(apiChangePassword, body)

const logout = () => http.get(apiLogout)
const loginGG = () => http.get(apiLoginGoole)
const loginZalo = body => http.post(apiLoginZalo, body)
const loginFB = () => http.get(apiLoginFB)
const loginFB2 = () => http.get(apiLoginFB2)

const callbackGG = params => http.get(apiCallBackLoginGoole, { params })

const businessRegister = body => http.post(apiBusinessRegister, body)

const AuthService = {
  loginGG,
  callbackGG,
  loginFB2,
  login,
  logout,
  register,
  forgotPass,
  verifyCode,
  verifyCodeRegister,
  resendOtp,
  businessRegister,
  changePassword,
  loginFB,
  loginZalo,
}
export default AuthService
