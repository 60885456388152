const BINH_CHON = "/binh-chon"
const CUOC_THI = "/cuoc-thi-binh-chon-can-bo-cong-doan-gioi"

const ROUTER = {
  // LOGIN: "/login",
  HOME: "/",
  ZALO_CONFIRM: "/zalo_verifierVTE60vJQ55bWhB43fv8mMNUmlG-RncvMDJKu.html",
  LOGIN_ZALO: "/login/zalo",
  LIEN_HE: "/lien-he",

  //Giới thiệu
  GIOI_THIEU: "/gioi-thieu",

  //Tin tức
  TIM_KIEM: "/tim-kiem",
  TIM_KIEM_VAN_BAN: "/tim-kiem-van-ban",
  TIM_KIEM_TAI_LIEU: "/tim-kiem-tai-lieu",
  DANH_SACH_VIDEO: "/danh-sach-video",
  TAI_LIEU_HDNV: "/sach,-tai-lieu-huong-dan-nghiep-vu",

  //Thư viện
  THU_VIEN: "/thu-vien",
  THU_VIEN_HINH_ANH: "/thu-vien-hinh-anh/:id",
  //DICH_VU
  DICH_VU: "/dich-vu",
  CAC_GOI_DICH_VU: "/cac-goi-dich-vu",
  CHI_TIET_DICH_VU: "/chi-tiet-dich-vu/:id",

  //Dịch vụ
  YEU_CAU_HO_TRO: "/yeu-cau-ho-tro",
  // NEWS

  MEDIA: "/media",
  DANH_SACH_TIN_TUC: "/danh-sach-tin-tuc",
  DANG_BAI: "/dang-bai",

  // USER
  TAI_KHOAN_CUA_TOI: "/tai-khoan-cua-toi",

  // ADMIN
  TONG_QUAN: "/tong-quan",
  VAN_BAN: "/van-ban",
  TAI_LIEU: "/tai-lieu",
  HINH_ANH: "/hinh-anh",
  VIDEO: "/video",
  PHAN_QUYEN: "/phan-quyen",
  BANNER_FOOTER: "/banner-footer",
  PHONG_BAN_CHUC_VU: "/phong-ban-chuc-vu",
  DANH_BA_NGUOI_DUNG: "/danh-ba-nguoi-dung",
  DANH_SACH_BAI_VIET: "/danh-sach-bai-viet",
  DANH_MUC_THE: "/danh-muc-the",
  DANH_BA: "/danh-ba",
  NHOM_TIN_BAI: "/nhom-tin-bai",
  LOAI_VAN_BAN: "/loai-van-ban",
  LINH_VUC: "/linh-vuc",
  LOAI_TAI_LIEU: "/loai-tai-lieu",
  CO_QUAN_BAN_HANH: "/co-quan-ban-hanh",
  NHOM_HINH_ANH: "/nhom-hinh-anh",
  LS_HOAT_DONG: "/ls-hoat-dong",
  HE_THONG_TO_CHUC: "/he-thong-to-chuc",
  THONG_KE: "/thong-ke",

  TIN_TUC_CHI_TIET: "/noi-dung/:id",
  GIOI_THIEU_TONG_QUAN: "/gioi-thieu-tong-quan",
  NEWS: "/noi-dung",
  TIN_TUC: "/tin-tuc",
  LOGIN_GOOGLE: "/dang-nhap-tu-mang-xa-hoi",
  SVG_VIEWER: "/svg-viewer",

  //Landing Page
  //Bình chọn tác giả
  BINH_CHON: BINH_CHON,
  DANH_SACH_TAC_GIA: BINH_CHON + "/danh-sach-tac-gia",
  THE_LE: BINH_CHON + "/the-le",
  BANG_XEP_HANG: BINH_CHON + "/bang-xep-hang",
  GIAI_THUONG: BINH_CHON + "/giai-thuong",

  CHI_TIET_TAC_GIA: BINH_CHON + "/chi-tiet-tac-gia",
  TIN_TUC_BINH_CHON: BINH_CHON + "/tin-tuc",
  CHI_TIET_TIN_TUC_BINH_CHON: BINH_CHON + "/chi-tiet-tin-tuc-binh-chon",
  //Cuộc thi
  CUOC_THI: CUOC_THI,
  THE_LE_CUOC_THI: CUOC_THI + "/the-le-cuoc-thi",
  BANG_XEP_HANG_CUOC_THI: CUOC_THI + "/bang-xep-hang-cuoc-thi",
  BAI_THI: CUOC_THI + "/bai-thi",
  BANG_THONG_KE: CUOC_THI + "/thong-ke",
  TIN_TUC_CUOC_THI: CUOC_THI + "/tin-tuc",
  CHI_TIET_TIN_TUC_CUOC_THI: CUOC_THI + "/chi-tiet-tin-tuc-binh-chon",
}
export default ROUTER
