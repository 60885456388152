import { Button, Col, Row } from "antd"
import { enc } from "crypto-js"
import React from "react"
import { useState } from "react"
import { useEffect } from "react"
import SvgIcon from "src/components/SvgIcon"
import STORAGE, { setStorage } from "src/lib/storage"
import AuthService from "src/services/AuthService"
import styled from "styled-components"
import zalo_icon from "./components/7044033_zalo_icon.png"

const Styled = styled.div`
  .box {
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    display: flex;
    padding: 14px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100%;
    height: auto !important;
  }
`
const FormLoginSocial = () => {
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    var e = document.createElement("script")
    e.async = true
    e.src = document.location.protocol + "//connect.facebook.net/en_US/all.js"
    document.getElementById("fb-root").appendChild(e)
  }, [])

  const loginGG = async () => {
    try {
      setLoading(true)
      const res = await AuthService.loginGG()
      if (res?.isError) return
      window.location.replace(res?.Object)
    } finally {
      setLoading(false)
    }
  }
  const loginFB = async () => {
    try {
      setLoading(true)
      const res = await AuthService.loginFB2()
      if (res?.isError) return
      window.location.replace(res?.Object)
    } finally {
      setLoading(false)
    }
  }
  // Mã hóa code_verifier bằng SHA-256 và sau đó encode Base64 (không padding)
  async function generateCodeChallenge(codeVerifier) {
    const encoder = new TextEncoder()
    const data = encoder.encode(codeVerifier)
    const hashBuffer = await crypto.subtle.digest("SHA-256", data)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const base64String = btoa(String.fromCharCode(...hashArray))
      .replace(/\+/g, "-")
      .replace(/\//g, "_")
      .replace(/=+$/, "")
    return base64String
  }
  function generateRandomString(length) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
    const charactersLength = characters.length
    let randomString = ""
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charactersLength)
      randomString += characters.charAt(randomIndex)
    }
    return randomString
  }
  const loginZalo = async () => {
    try {
      setLoading(true)
      // const res = await AuthService.loginFB2()
      // if (res?.isError) return
      // const code_verifier = "Ab@0123456789012345678901234567890123456787"
      const code_verifier = "Ab@1" + generateRandomString(39)
      setStorage(STORAGE?.code_verifier_zalo, code_verifier)
      const CODE_CHALLENGE = await generateCodeChallenge(code_verifier)
      // const code_challenge = Base64.encode(SHA-256.hash(ASCII(code_verifier)))
      const IDApp = "291850472441392582"
      const CALLBACK_URL = window.location.origin + "/login/zalo"
      // const CALLBACK_URL = "https://cdyt.vn/login/zalo"
      const STATE = "123123"
      // console.log(CODE_CHALLENGE?.length)
      // console.log(
      //   `https://oauth.zaloapp.com/v4/permission?app_id=${IDApp}&redirect_uri=${CALLBACK_URL}&code_challenge=${CODE_CHALLENGE}&state=${STATE}`,
      // )
      window.location.replace(
        `https://oauth.zaloapp.com/v4/permission?app_id=${IDApp}&redirect_uri=${CALLBACK_URL}&code_challenge=${CODE_CHALLENGE}&state=${STATE}`,
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <Styled>
      <Row gutter={16}>
        <Col span={24}>
          <Button className="box" onClick={loginFB} disabled={loading}>
            <SvgIcon name="login-facebook" />
            <div className="ml-16">Đăng nhập bằng Facebook</div>
          </Button>
        </Col>
        <Col span={24} className="mt-12">
          <Button className="box" onClick={loginZalo} disabled={loading}>
            <img
              src={zalo_icon}
              alt="zalo"
              style={{
                height: "32px",
                width: "32px",
              }}
            />
            <div className="ml-16">Đăng nhập bằng Zalo</div>
          </Button>
        </Col>
        {/* <Col span={12}>
          <Button className="box" onClick={loginGG} disabled={loading}>
            <SvgIcon name="login-google" />
            <div className="ml-16">Google</div>
          </Button>
        </Col> */}
      </Row>
    </Styled>
  )
}

export default FormLoginSocial
