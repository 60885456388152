import { Col, Form, Input, Row, Select } from "antd"
import { useEffect, useState } from "react"
import { StyleRegisterModal } from "src/components/Layouts/styled"
import CustomModal from "src/components/Modal/CustomModal"
import Button from "src/components/MyButton/Button"
import Notice from "src/components/Notice"
import SvgIcon from "src/components/SvgIcon"
import {
  getRegexEmail,
  getRegexMobile,
  getRegexPassword,
  getRegexUsername,
} from "src/lib/stringsUtils"
import AuthService from "src/services/AuthService"
import GuestServices from "src/services/GuestServices"

const RegisterModal = ({
  openRegisterModal,
  handleOk,
  handleCancel,
  handleLogin,
}) => {
  // const handleCredentialResponse = e => console.log(e)

  // const signInFb = async () => {
  //   const { authResponse } = await new Promise(window.FB.login)
  // }
  // useEffect(() => {
  //   window.google.accounts.id.initialize({
  //     client_id:
  //       "682918075401-n7bbr0pgvd4oknkbrahtoc8l43k52e8c.apps.googleusercontent.com",
  //     callback: handleCredentialResponse,
  //   })
  //   window.google.accounts.id.renderButton(
  //     document.getElementById("signInDiv"),
  //     {
  //       theme: "outline",
  //       size: "medium",
  //     },
  //   )

  //   var e = document.createElement("script")
  //   e.async = true
  //   e.src = document.location.protocol + "//connect.facebook.net/en_US/all.js"
  //   document.getElementById("fb-root").appendChild(e)
  // }, [])
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const [account, setAccount] = useState(false)
  const [position, setPosition] = useState(false)

  const handleSubmit = () => {
    form.validateFields().then(values => {
      setLoading(true)
      const body = {
        ...values,
      }
      AuthService.register(body)
        .then(res => {
          if (res.isError) return
          Notice({
            isSuccess: true,
            msg: "Đăng ký thành công",
          })
          handleCancel()
        })
        .finally(() => setLoading(false))
    })
  }
  useEffect(() => {
    getListAccount()
    getListPosition()
  }, [])

  const getListAccount = async () => {
    try {
      setLoading(true)
      const res = await GuestServices.guestGetAccountComboBox()
      if (res?.isError) return
      setAccount(pre => res?.Object?.data)
    } finally {
      setLoading(false)
    }
  }
  const getListPosition = async () => {
    try {
      setLoading(true)
      const res = await GuestServices.guestGetListPositionForCombobox()
      if (res?.isError) return
      setPosition(pre => res?.Object?.data)
    } finally {
      setLoading(false)
    }
  }

  return (
    <CustomModal
      style={{ top: "40px" }}
      title="Đăng ký"
      width="700px"
      footer={null}
      open={openRegisterModal}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <StyleRegisterModal>
        <Form layout="vertical" form={form}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa nhập tên tài khoản!",
                  },
                  {
                    pattern: getRegexUsername(),
                    message:
                      "Tài khoản có độ dài từ 6-16 ký tự và không có khoảng trắng!",
                  },
                ]}
                label="Tên đăng nhập"
                name="UserName"
              >
                <Input placeholder="Nhập tên đăng nhập" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa nhập họ và tên!",
                  },
                ]}
                label="Họ và tên"
                name="FullName"
              >
                <Input placeholder="Nhập họ và tên" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Số điện thoại bàn"
                name="DeskPhoneNumber"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Thông tin không được để trống!",
                  // },
                  {
                    pattern: getRegexMobile(),
                    message: "Số điện thoại là chuỗi từ 8 đến 15 kí tự chữ số",
                  },
                ]}
              >
                <Input placeholder="Nhập số điện thoại" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Số điện thoại di động"
                name="PhoneNumber"
                rules={[
                  // {
                  //   required: true,
                  //   message: "Thông tin không được để trống!",
                  // },
                  {
                    pattern: getRegexMobile(),
                    message: "Số điện thoại là chuỗi từ 8 đến 15 kí tự chữ số",
                  },
                ]}
              >
                <Input placeholder="Nhập số điện thoại" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Email"
                name="Email"
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống!",
                  },
                  {
                    pattern: getRegexEmail(),
                    message: "Email sai định dạng",
                  },
                ]}
              >
                <Input placeholder="Nhập email" />
              </Form.Item>
            </Col>

            <Col xs={24} md={12}>
              <Form.Item
                label="Chọn đơn vị"
                name="AccountID"
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống!",
                  },
                ]}
              >
                <Select
                  placeholder="Chọn"
                  showSearch
                  optionFilterProp="children"
                >
                  {account?.length &&
                    account?.map((item, idx) => (
                      <Select.Option
                        value={item?.AccountID}
                        key={`accountid${idx}`}
                      >
                        {item?.AccountName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                label="Chọn chức vụ"
                name="PositionID"
                rules={[
                  {
                    required: true,
                    message: "Thông tin không được để trống!",
                  },
                ]}
              >
                <Select
                  placeholder="Chọn"
                  showSearch
                  optionFilterProp="children"
                >
                  {!!position?.length &&
                    position?.map((item, idx) => (
                      <Select.Option
                        value={item?.PositionID}
                        key={`positonid${idx}`}
                      >
                        {item?.PositionName}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Bạn chưa nhập mật khẩu!",
                  },
                  {
                    pattern: getRegexPassword(),
                    message:
                      "Mật khẩu có chứa ít nhất 8 ký tự, trong đó có ít nhất một số và bao gồm cả chữ thường và chữ hoa và ký tự đặc biệt, ví dụ @, #, ?, !.",
                  },
                ]}
                label="Mật khẩu"
                name="Password"
              >
                <Input.Password placeholder="Nhập mật khẩu" />
              </Form.Item>
            </Col>
            <Col xs={24} md={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Bạn phải nhập lại mật khẩu một lần nữa",
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("Password") === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error("Mật khẩu không khớp!"))
                    },
                  }),
                ]}
                label="Nhập lại mật khẩu"
                name="RePassword"
              >
                <Input.Password placeholder="Nhập lại mật khẩu" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Button
              btnType="primary"
              className="btn-register"
              type="submit"
              htmlType="submit"
              loading={loading}
              onClick={handleSubmit}
            >
              Đăng ký
            </Button>
          </Row>

          {/* <div className="center">Hoặc</div>
          <Row gutter={14} className="or">
            <Col span={12}>
              <Button className="facebook-btn" onClick={() => signInFb()}>
                <SvgIcon name="face-book" />
                Đăng nhập bằng Facebook
              </Button>
            </Col>
            <Col span={12}>
              <div id="signInDiv" />
            </Col>
          </Row> */}
          <Row className="register">
            <span>
              Bạn đã có tài khoản?{" "}
              <span
                className="link-login"
                onClick={() => {
                  handleCancel()
                  handleLogin()
                }}
              >
                Đăng nhập
              </span>{" "}
              ngay
            </span>
          </Row>
        </Form>
      </StyleRegisterModal>
    </CustomModal>
  )
}

export default RegisterModal
